// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: protos/permissions/permissions.proto

import { Caller } from '@altipla/grpc-browser';


export default class PermissionsServiceClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/permissions`, req, false, []);
  }

  Get(req) {
    {
      let parts = `${req.name}`.split('/');
      let fmt = `parameter req.name should have the format "permissions/*"`;
      if (parts.length !== 2) { throw new Error(fmt); }
      if (parts[0] !== 'permissions') { throw new Error(fmt); }
    }
    return this._caller.send('GET', `/${req.name}`, req, false, ['name']);
  }

  BatchGet(req) {
    return this._caller.send('POST', `/permissions:batchGet`, req, true, []);
  }

  Create(req) {
    return this._caller.send('POST', `/permissions`, req, true, []);
  }

  Update(req) {
    {
      let parts = `${req.name}`.split('/');
      let fmt = `parameter req.name should have the format "permissions/*"`;
      if (parts.length !== 2) { throw new Error(fmt); }
      if (parts[0] !== 'permissions') { throw new Error(fmt); }
    }
    return this._caller.send('PUT', `/${req.name}`, req, true, ['name']);
  }

  Delete(req) {
    {
      let parts = `${req.name}`.split('/');
      let fmt = `parameter req.name should have the format "permissions/*"`;
      if (parts.length !== 2) { throw new Error(fmt); }
      if (parts[0] !== 'permissions') { throw new Error(fmt); }
    }
    return this._caller.send('DELETE', `/${req.name}`, req, false, ['name']);
  }
};

