
<template>
  <div class="p-4 border border-red-300 rounded-md bg-red-50">
    <div class="flex">
      <div class="flex-shrink-0">
        <ac-icon solid size="lg" class="text-red-500">exclamation-circle</ac-icon>
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800 leading-5">
          Ha ocurrido un error inesperado al cargar la página
        </h3>
        <div class="mt-2 text-sm text-red-700 leading-5">
          Nuestros técnicos han recibido el aviso. Puedes intentar probar de nuevo.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
