
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFileExclamation as faFileExclamationLight,
} from '@fortawesome/pro-light-svg-icons'
import {
  faExclamationCircle as faExclamationCircleSolid,
  faPlus as faPlusSolid,
  faTrash as faTrashSolid,
  faUserLock as faUserLockSolid,
  faUserUnlock as faUserUnlockSolid,
  faRepeat as faRepeatSolid,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faCircleNotch as faCircleNotchDuotone,
} from '@fortawesome/pro-duotone-svg-icons'


library.add(
  faCircleNotchDuotone,
  faExclamationCircleSolid,
  faFileExclamationLight,
  faPlusSolid,
  faTrashSolid,
  faUserLockSolid,
  faUserUnlockSolid,
  faRepeatSolid,
)
