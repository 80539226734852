
<template>
  <ac-acl-tabs selected="groups">
    <ac-button-primary @click="create = true">
      <ac-icon solid class="mr-2">plus</ac-icon>
      Crear grupo
    </ac-button-primary>
  </ac-acl-tabs>

  <ac-card-table empty="No hay grupos que coincidan con el filtro">
    <template #title>Grupos de usuarios</template>
    <template #description>Se crean desde este panel administrativo y ayudan a gestionar los usuarios por grupos.</template>
    <template #actions>
      <ac-search-input placeholder="Filtrar grupos..." v-model="table.filter" v-if="table"></ac-search-input>
    </template>

    <template #header>
      <ac-cell-header class="w-full">Nombre</ac-cell-header>
      <ac-cell-header></ac-cell-header>
    </template>

    <template #rows v-if="table">
      <ac-row v-for="group in table.rows" :key="group.name">
        <ac-cell class="w-full">
          <router-link :to="`/${group.name}`" class="text-gray-900">
            {{group.displayName}}
          </router-link>
        </ac-cell>
        <ac-cell>
          <a :href="`/${group.name}`" @click.prevent="group.showEdit = true">Editar</a>
        </ac-cell>
      </ac-row>
    </template>

    <template #footer>
      <ac-pagination :table="table"></ac-pagination>
    </template>
  </ac-card-table>

  <SheetCreate v-model="create"></SheetCreate>
  <template v-if="table">
    <template v-for="group in table.rows" :key="group.name">
      <SheetEdit v-model="group.showEdit" :edit="group.edit" @submit="$event.waitUntil(loadGroups())"></SheetEdit>
    </template>
  </template>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { useTable } from '@altipla-consulting/ui-v1'

import GroupsServiceClient from '@self/protos/groups/groups'
import { gliderEndpoint } from '/platform/discovery'

import SheetCreate from './SheetCreate.vue'
import SheetEdit from './SheetEdit.vue'


const client = new GroupsServiceClient(gliderEndpoint())


export default {
  components: {
    SheetCreate,
    SheetEdit,
  },

  data() {
    return {
      table: null,
      create: false,
    }
  },

  async navigate() {
    await this.loadGroups()
  },

  methods: {
    async loadGroups() {
      let { groups } = await client.List()

      this.table = useTable({
        rows: groups.map(group => {
          group.edit = cloneDeep(group)

          return group
        }),
        search: group => [group.displayName],
      })
    },
  },
}
</script>
