// Code generated by protoc-gen-grpc_browser v1, DO NOT EDIT.
// Source: protos/users/users.proto

import { Caller } from '@altipla/grpc-browser';


export default class UsersServiceClient {
  constructor(opts = {}) {
    this._caller = new Caller(opts);
  }

  List(req) {
    return this._caller.send('GET', `/users`, req, false, []);
  }

  Get(req) {
    {
      let parts = `${req.name}`.split('/');
      let fmt = `parameter req.name should have the format "users/*"`;
      if (parts.length !== 2) { throw new Error(fmt); }
      if (parts[0] !== 'users') { throw new Error(fmt); }
    }
    return this._caller.send('GET', `/${req.name}`, req, false, ['name']);
  }

  Create(req) {
    return this._caller.send('POST', `/users`, req, true, []);
  }

  Update(req) {
    {
      let parts = `${req.name}`.split('/');
      let fmt = `parameter req.name should have the format "users/*"`;
      if (parts.length !== 2) { throw new Error(fmt); }
      if (parts[0] !== 'users') { throw new Error(fmt); }
    }
    return this._caller.send('PUT', `/${req.name}`, req, true, ['name']);
  }

  Delete(req) {
    {
      let parts = `${req.name}`.split('/');
      let fmt = `parameter req.name should have the format "users/*"`;
      if (parts.length !== 2) { throw new Error(fmt); }
      if (parts[0] !== 'users') { throw new Error(fmt); }
    }
    return this._caller.send('DELETE', `/${req.name}`, req, false, ['name']);
  }

  Disable(req) {
    {
      let parts = `${req.name}`.split('/');
      let fmt = `parameter req.name should have the format "users/*"`;
      if (parts.length !== 2) { throw new Error(fmt); }
      if (parts[0] !== 'users') { throw new Error(fmt); }
    }
    return this._caller.send('POST', `/${req.name}:disable`, req, true, ['name']);
  }

  Enable(req) {
    {
      let parts = `${req.name}`.split('/');
      let fmt = `parameter req.name should have the format "users/*"`;
      if (parts.length !== 2) { throw new Error(fmt); }
      if (parts[0] !== 'users') { throw new Error(fmt); }
    }
    return this._caller.send('POST', `/${req.name}:enable`, req, true, ['name']);
  }
};

