
<template>
  <ac-header>
    <template v-slot:breadcrumbs>
      <ac-breadcrumb url="/users">Usuarios</ac-breadcrumb>
      <ac-breadcrumb :url="`/users/${route.params.code}`">{{user.givenName}} {{user.lastname}}</ac-breadcrumb>
    </template>
    <template v-slot:actions>
      <ac-button-primary class="mr-4" @click="edit = true">
        <ac-icon solid class="mr-2">pencil</ac-icon>
        Editar usuario
      </ac-button-primary>
      <ac-button-async-success class="mr-4" v-if="user.disabled" @action="$event.waitUntil(enableUser())">
        <ac-icon solid class="mr-2">user-unlock</ac-icon>
        Activar usuario
      </ac-button-async-success>
      <ac-button-async-hover-danger class="mr-4" v-if="!user.disabled" @action="$event.waitUntil(disableUser())">
        <ac-icon solid class="mr-2">user-lock</ac-icon>
        Desactivar usuario
      </ac-button-async-hover-danger>
      <ac-button-confirm-danger @confirm="$event.waitUntil(deleteUser())">
        <ac-icon solid class="mr-2">trash</ac-icon>
        Eliminar usuario
      </ac-button-confirm-danger>
    </template>
  </ac-header>

  <ac-alert-success v-if="password" class="mb-8">
    <template #icon><ac-icon solid class="ui-mt-0.5 ui-text-2xl">check-circle</ac-icon></template>
    <template #title>Nueva contraseña</template>
    <template #content>
      <p>La nueva contraseña del usuario es <code class="font-mono font-medium">{{password}}</code></p>
      <p>Apúntela en un lugar seguro porque no volverá a mostrarse nunca más. Puede resetearla cuantas veces necesite a través de la página para recuperar contraseña si el usuario pierde el acceso en cualquier momento.</p>

      <ac-button-success class="mt-4" @click="password = ''">Aceptar</ac-button-success>
    </template>
  </ac-alert-success>

  <div class="flex space-x-4">
    <div class="flex flex-col items-center w-40 h-40 mt-5 space-y-3">
      <template v-if="user.picture">
        <img class="w-32 h-32 border-gray-600 rounded-full" :src="user.picture"/>
        <span class="font-medium cursor-pointer hover:underline" @click="edit = true">Editar</span>
      </template>
    </div>
    <div class="flex-1">
      <ac-alert-error v-if="user.disabled" class="mb-4">
        <template #icon><ac-icon solid>user-lock</ac-icon></template>
        <template #title>Esta cuenta ha sido deshabilitada y no podrá iniciar sesión en el sistema.</template>
      </ac-alert-error>
      <ac-card fit>
        <template v-slot:title>Datos personales</template>
        <template v-slot:content>
          <ac-show class="relative">
            <ac-show-pane name="Nombre">{{user.givenName}}</ac-show-pane>
            <ac-show-pane name="Apellidos">{{user.lastname}}</ac-show-pane>
            <ac-show-pane name="Email">
              <ac-copy-text class="font-mono relative -top-0.5" :value="user.email">{{user.email}}</ac-copy-text>
              <ac-alert-error v-if="user.name && !user.emailVerified" class="mt-4">
                <template v-slot:icon><ac-icon solid>times-circle</ac-icon></template>
                <template v-slot:title>El email no está verificado</template>
              </ac-alert-error>
            </ac-show-pane>
          </ac-show>
        </template>
      </ac-card>
    </div>
  </div>

  <div class="mt-6 grid grid-cols-2 gap-6">
    <ac-card-table empty="Este usuario no pertenece a ningún grupo">
      <template v-slot:title>Grupos del usuario</template>
      <template v-slot:header>
        <ac-cell-header>Nombre</ac-cell-header>
      </template>
      <template v-slot:rows>
        <ac-row v-for="group in user.groups" :key="group.name">
          <ac-cell class="w-full">
            <router-link :to="`/${group.name}`">{{group.displayName}}</router-link>
          </ac-cell>
        </ac-row>
      </template>
    </ac-card-table>
    <ac-card fit>
      <template v-slot:title>Estadísticas</template>
      <template v-slot:content>
        <ac-show>
          <ac-show-pane name="Fecha de creación">{{user.createTime}}</ac-show-pane>
          <ac-show-pane name="Último acceso">{{user.lastLoginTime}}</ac-show-pane>
          <ac-show-pane name="IP del último acceso">
            <span class="font-mono">{{user.lastIp}}</span>
          </ac-show-pane>
          <ac-show-pane name="Accesos totales">{{user.logins}}</ac-show-pane>
        </ac-show>
      </template>
    </ac-card>
  </div>
  <SheetEdit v-model="edit" :edit="user.edit" @submit="$event.waitUntil(loadUser())"></SheetEdit>
</template>

<script>
import { parseTimestamp, formatDate } from '@altipla/filters'
import { cloneDeep } from 'lodash-es'

import UsersServiceClient from '@self/protos/users/users'
import GroupsServiceClient from '@self/protos/groups/groups'
import { gliderEndpoint } from '/platform/discovery'

import SheetEdit from './SheetEdit.vue'


const usersClient = new UsersServiceClient(gliderEndpoint())
const groupsClient = new GroupsServiceClient(gliderEndpoint())


let password


export function showPassword(pass) {
  password = pass
}


export default {
  components: { SheetEdit },

  data() {
    return {
      user: {},
      edit: false,
      lastLoginTime: '',
      created: '',
      password: '',
    }
  },

  async navigate() {
    if (password) {
      this.password = password
      password = ''
    }
    this.loadUser()
  },

  methods: {
    async deleteUser() {
      await usersClient.Delete({ name: this.user.name })

      this.router.navigate(`/users`)
    },

    async loadUser() {
      this.user = await usersClient.Get({ name: `users/${this.route.params.code}` })
      let { groups } = await groupsClient.BatchGet({ names: this.user.groups })
      
      this.user.edit = cloneDeep(this.user)
      this.user = {
        ...this.user,
        groups: groups,
        lastLoginTime: formatDate('DATETIME', parseTimestamp(this.user.lastLoginTime)),
        createTime: formatDate('DATETIME', parseTimestamp(this.user.createTime)),
      }

    },

    async enableUser() {
      await usersClient.Enable({ name: this.user.name })
      this.user.disabled = false
    },

    async disableUser() {
      await usersClient.Disable({ name: this.user.name })
      this.user.disabled = true
    },
  },
}
</script>
