
<template>
  <ac-side-sheet-form
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @submit="$event.waitUntil(submit())"
    @load="$event.waitUntil(load())"
  >
    <template v-slot:title>Editar permiso</template>
    <template v-slot:content>
      <ac-fieldset>
        <ac-input class="w-96" name="code" label="Código" :model-value="code" disabled>
          <template v-slot:help>El código de un permiso no se puede editar, pero es posible <a href="#" @click.prevent="$emit('create')">crear uno nuevo</a> con el código deseado.</template>
        </ac-input>
      </ac-fieldset>
      <ac-fieldset>
        <ac-input name="displayName" label="Nombre" rules="required|min:3|no_end_point" v-model="input.displayName"></ac-input>
      </ac-fieldset>
      <ac-fieldset wide>
        <ac-dual-list name="roles" label="Roles" :items="roles" v-model="input.roles"></ac-dual-list>
      </ac-fieldset>
    </template>
    <template v-slot:submit>
      <ac-submit fit>Guardar permiso</ac-submit>
    </template>
  </ac-side-sheet-form>
</template>

<script>
import { cloneDeep, difference, keyBy } from 'lodash-es'
import { runAction } from '@altipla/promises'

import PermissionsServiceClient from '@self/protos/permissions/permissions'
import RolesServiceClient from '@self/protos/roles/roles'
import { gliderEndpoint } from '/platform/discovery'


const permissionsClient = new PermissionsServiceClient(gliderEndpoint())
const rolesClient = new RolesServiceClient(gliderEndpoint())


export default {
  name: 'SheetEdit',

  props: {
    modelValue: Boolean,
    edit: Object,
  },
  emits: ['update:modelValue', 'submit', 'create'],

  data() {
    return {
      input: {
        displayName: '',
        roles: [],
      },
      code: '',
      roles: [],
    }
  },

  methods: {
    async load() {
      this.input = cloneDeep(this.edit)
      this.code = this.edit.name.split('/')[1]

      let { roles } = await rolesClient.List()
      this.input.roles = roles
        .filter(role => role.permissions.includes(this.edit.name))
        .map(role => role.name)

      this.roles = roles.map(role => {
        return {
          id: role.name,
          label: role.displayName,
        }
      })
    },

    async submit() {
      let perm = cloneDeep(this.input)
      delete perm.roles
      await permissionsClient.Update(perm)

      let { roles } = await rolesClient.List()
      let initialRoles = roles
        .filter(role => role.permissions.includes(perm.name))
        .map(role => role.name)

      roles = keyBy(roles, 'name')

      let addedRoles = difference(this.input.roles, initialRoles)
      for (let name of addedRoles) {
        let role = roles[name]
        role.permissions.push(perm.name)
        await rolesClient.Update(role)
      }

      let removedRoles = difference(initialRoles, this.input.roles)
      for (let name of removedRoles) {
        let role = roles[name]
        role.permissions.splice(role.permissions.indexOf(perm.name), 1)
        await rolesClient.Update(role)
      }

      await runAction(this.$emit, 'submit')
    },
  },
}
</script>
