
<template>
  <ac-header>
    <template v-slot:breadcrumbs>
      <ac-breadcrumb url="/users">Usuarios</ac-breadcrumb>
    </template>
    <template v-slot:actions>
      <ac-button-primary @click="create = true">
        <ac-icon solid class="mr-2">plus</ac-icon>
        Crear usuario
      </ac-button-primary>
    </template>
  </ac-header>

  <ac-card-table empty="No hay usuarios que coincidan con el filtro">
    <template #title>Usuarios</template>
    <template #description>Usuarios del equipo autorizados a entrar al panel administrativo.</template>
    <template #actions>
      <ac-search-input placeholder="Filtrar usuarios..." v-model="table.filter" v-if="table"></ac-search-input>
    </template>

    <template #header>
      <ac-cell-header fit="right"></ac-cell-header>
      <ac-cell-header fit="left">Nombre</ac-cell-header>
      <ac-cell-header>Grupos</ac-cell-header>
      <ac-cell-header>Último acceso</ac-cell-header>
      <ac-cell-header></ac-cell-header>
    </template>

    <template #rows v-if="table">
      <ac-row v-for="user in table.rows" :key="user.name">
        <ac-cell fit="right">
          <div class="w-12 h-12 -my-1.5 mr-4">
            <img class="rounded-full" :src="user.picture">
          </div>
        </ac-cell>
        <ac-cell class="w-1/3" fit="left">
          <div class="flex space-x-4 items-center">
            <div>
              <router-link :to="`/${user.name}`" class="text-gray-900 font-medium">
                {{user.givenName}} {{user.lastname}}
              </router-link>
            </div>
            <div>
              <ac-badge-red v-if="user.disabled" class="relative -top-1">Usuario desactivado</ac-badge-red>
            </div>
          </div>
          <div class="text-gray-400">
            {{user.email}}
            <span title="El correo no está verificado" v-if="!user.emailVerified">
              <ac-icon solid class="ml-0.5 text-red-400">exclamation-circle</ac-icon>
            </span>
          </div>
        </ac-cell>
        <ac-cell class="w-1/4">
          <template v-if="!user.groups.length">
            Ningún grupo
          </template>
          <div v-if="user.groups.length <= 2" class="space-y-0.5">
            <div v-for="group in user.groups" :key="group.name">
              <ac-external-link :href="`/${group.name}`" class="text-gray-900">
                {{group.displayName}}
              </ac-external-link>
            </div>
          </div>
          <a :href="`/${user.name}`" class="text-gray-900" @click.prevent="showGroups(user)" v-if="user.groups.length > 2">
            {{user.groups.length}} grupos
          </a>
        </ac-cell>
        <ac-cell class="w-1/3">
          {{user.lastLoginTime}}
        </ac-cell>
        <ac-cell class="text-right">
          <a :href="`/${user.name}`" @click.prevent="user.showEdit = true">Editar</a>
        </ac-cell>
      </ac-row>
    </template>

    <template #footer>
      <ac-pagination :table="table"></ac-pagination>
    </template>
  </ac-card-table>

  <ac-side-sheet v-model="expandGroups.open">
    <template v-slot:title>Grupos</template>
    <template v-slot:content>
      <h3 class="font-medium text-gray-900">Usuario</h3>
      <p class="mt-2 text-sm text-gray-600">{{expandGroups.user.givenName}} {{expandGroups.user.lastname}}</p>

      <h3 class="font-medium text-gray-900 mt-8">Grupos</h3>
      <dl class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
        <div class="py-3 flex justify-between text-sm font-medium" v-for="group in expandGroups.user.groups" :key="group.name">
          <dt class="text-gray-500">{{group.displayName}}</dt>
          <dd>
            <ac-external-link :to="`/${group.name}`" :hover-icon="false">Consultar</ac-external-link>
          </dd>
        </div>
      </dl>
    </template>
  </ac-side-sheet>

  <SheetCreate v-model="create"></SheetCreate>
  <template v-if="table">
    <template v-for="user in table.rows" :key="user.name">
      <SheetEdit v-model="user.showEdit" :edit="user.edit" @submit="$event.waitUntil(loadUsers())"></SheetEdit>
    </template>
  </template>
</template>

<script>
import { keyBy, flatten, uniq } from 'lodash-es'
import { parseTimestamp, formatDate } from '@altipla/filters'
import { resolveAll } from '@altipla/promises'
import { cloneDeep } from 'lodash-es'
import thenBy from 'thenby'
import { useTable } from '@altipla-consulting/ui-v1'

import UsersServiceClient from '@self/protos/users/users'
import GroupsServiceClient from '@self/protos/groups/groups'
import RolesServiceClient from '@self/protos/roles/roles'
import PermissionsServiceClient from '@self/protos/permissions/permissions'
import { gliderEndpoint } from '/platform/discovery'

import SheetCreate from './SheetCreate.vue'
import SheetEdit from './SheetEdit.vue'


const usersClient = new UsersServiceClient(gliderEndpoint())
const groupsClient = new GroupsServiceClient(gliderEndpoint())
const rolesClient = new RolesServiceClient(gliderEndpoint())
const permissionsClient = new PermissionsServiceClient(gliderEndpoint())


export default {
  components: {
    SheetCreate,
    SheetEdit,
  },

  data() {
    return {
      table: null,
      expandGroups: {
        open: false,
        user: {},
      },
      create: false,
    }
  },

  async navigate() {
    await this.loadUsers()
  },

  methods: {
    showGroups(user) {
      this.expandGroups.user = user
      this.expandGroups.open = true
    },
    
    async loadUsers() {
      let { users, groups, roles, permissions } = await resolveAll({
        users: usersClient.List().then(reply => reply.users),
        groups: groupsClient.List().then(reply => reply.groups),
        roles: rolesClient.List().then(reply => reply.roles),
        permissions: permissionsClient.List().then(reply => reply.permissions),
      })

      groups = keyBy(groups, 'name')
      roles = keyBy(roles, 'name')
      permissions = keyBy(permissions, 'name')

      users.forEach(user => {
        user.edit = cloneDeep(user)
        user.lastLoginTime = formatDate('DATETIME', parseTimestamp(user.lastLoginTime))
        user.groups = user.groups.map(group => groups[group])
        user.roles = user.groups.map(group => group.roles.map(role => roles[role]))
        user.roles = uniq(flatten(user.roles))
      })
      users.sort(thenBy('lastLoginTime', 'desc'))

      this.table = useTable({
        rows: users,
        search: user => {
          let groups = user.groups.map(group => group.displayName)
          let roles = user.roles.map(role => role.displayName)
          let perms = user.roles.map(role => role.permissions.map(perm => permissions[perm]))
          perms = uniq(flatten(perms)).map(perm => perm.displayName)

          return [
            user.givenName,
            user.lastname,
            user.email,
            groups.join(' '),
            roles.join(' '),
            perms.join(' '),
          ]
        },
      })
    },
  },
}
</script>
