
<template>
  <ac-side-sheet-form
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @submit="$event.waitUntil(submit())"
    @load="$event.waitUntil(load())"
  >
    <template v-slot:title>Crear grupo</template>
    <template v-slot:content>
      <ac-fieldset>
        <ac-input name="displayName" label="Nombre" rules="required|min:3|no_end_point" v-model="input.displayName"></ac-input>
      </ac-fieldset>
      <ac-fieldset wide>
        <ac-dual-list name="roles" label="Roles" :items="roles" v-model="input.roles"></ac-dual-list>
      </ac-fieldset>
    </template>
    <template v-slot:submit>
      <ac-submit>Crear grupo</ac-submit>
    </template>
  </ac-side-sheet-form>
</template>

<script>
import GroupsServiceClient from '@self/protos/groups/groups'
import RolesServiceClient from '@self/protos/roles/roles'
import { gliderEndpoint } from '/platform/discovery'


const groupsClient = new GroupsServiceClient(gliderEndpoint())
const rolesClient = new RolesServiceClient(gliderEndpoint())


export default {
  name: 'SheetCreate',

  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],

  data() {
    return {
      input: {
        displayName: '',
        roles: [],
      },
      roles: [],
    }
  },

  methods: {
    async load() {
      let { roles } = await rolesClient.List()
      this.roles = roles.map(role => {
        return {
          id: role.name,
          label: role.displayName,
        }
      })
    },

    async submit() {
      let reply = await groupsClient.Create(this.input)

      this.router.navigate(`/${reply.name}`)
    },
  },
}
</script>
