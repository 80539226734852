
<template>
  <ac-header>
    <template v-slot:breadcrumbs>
      <ac-breadcrumb url="/permissions">Permisos</ac-breadcrumb>
      <ac-breadcrumb :url="`/${perm.name}`">{{perm.code}}</ac-breadcrumb>
    </template>
    <template v-slot:actions>
      <ac-button-primary class="mr-4" @click="perm.showEdit = true">
        <ac-icon solid class="mr-2">pencil</ac-icon>
        Editar permiso
      </ac-button-primary>
      <ac-button-confirm-danger @confirm="$event.waitUntil(deletePermission())">
        <ac-icon solid class="mr-2">trash</ac-icon>
        Eliminar permiso
      </ac-button-confirm-danger>
    </template>
  </ac-header>

  <ac-card fit>
    <template v-slot:title>Datos generales</template>
    <template v-slot:content>
      <ac-show>
        <ac-show-pane name="Nombre">{{perm.displayName}}</ac-show-pane>
        <ac-show-pane name="Código">
          <ac-copy-text :value="perm.code">
            <code>{{perm.code}}</code>
          </ac-copy-text>
        </ac-show-pane>
      </ac-show>
    </template>
  </ac-card>

  <div class="mt-8 grid grid-cols-2 gap-4">
    <ac-card-table empty="No hay roles con el permiso">
      <template v-slot:title>Roles con el permiso</template>
        <template v-slot:header>
          <ac-cell-header>Nombre</ac-cell-header>
        </template>
        <template v-slot:rows>
          <ac-row v-for="role in roles" :key="role.name">
            <ac-cell class="w-full">
              <router-link :to="`/${role.name}`">{{role.displayName}}</router-link>
            </ac-cell>
          </ac-row>
        </template>
    </ac-card-table>
    <ac-card-table empty="No hay grupos con el permiso">
      <template v-slot:title>Grupos con el permiso</template>
        <template v-slot:header>
          <ac-cell-header>Nombre</ac-cell-header>
        </template>
        <template v-slot:rows>
          <ac-row v-for="group in groups" :key="group.name">
            <ac-cell class="w-full">
              <router-link :to="`/${group.name}`">{{group.displayName}}</router-link>
            </ac-cell>
          </ac-row>
        </template>
    </ac-card-table>
  </div>
  <SheetCreate v-model="create"></SheetCreate>
  <SheetEdit v-model="perm.showEdit" :edit="perm.edit" @submit="$event.waitUntil(loadPermission())" @create="switchToCreate(perm)"></SheetEdit>
</template>

<script>
import PermissionsServiceClient from '@self/protos/permissions/permissions'
import RolesServiceClient from '@self/protos/roles/roles'
import GroupsServiceClient from '@self/protos/groups/groups'
import { gliderEndpoint } from '/platform/discovery'
import { resolveAll } from '@altipla/promises'
import { cloneDeep } from 'lodash-es'

import SheetEdit from './SheetEdit.vue'
import SheetCreate from './SheetCreate.vue'


const permissionsClient = new PermissionsServiceClient(gliderEndpoint())
const rolesClient = new RolesServiceClient(gliderEndpoint())
const groupsClient = new GroupsServiceClient(gliderEndpoint())


export default {
  components: {
    SheetEdit,
    SheetCreate,
  },

  data() {
    return {
      perm: {},
      roles: [],
      groups: [],
      create: false,
    }
  },

  async navigate() {
    let { permission, roles, groups } = await resolveAll({
      permission: permissionsClient.Get({ name: `permissions/${this.route.params.code}` }),
      roles: rolesClient.List().then(reply => reply.roles),
      groups: groupsClient.List().then(reply => reply.groups),
    })
    this.perm = this.parsePermission(permission)
    this.roles = roles.filter(role => role.permissions.includes(this.perm.name))

    let names = this.roles.map(role => role.name)
    this.groups = groups.filter(group => group.roles.some(role => names.includes(role)))
  },

  methods: {
    async deletePermission() {
      await permissionsClient.Delete({ name: this.perm.name })

      this.router.navigate(`/permissions`)
    },

    async loadPermission() {
      let permission = await permissionsClient.Get({ name: `permissions/${this.route.params.code}` })
      this.perm = this.parsePermission(permission)
    },

    parsePermission(permission) {
      permission.edit = cloneDeep(permission)
      permission.code = permission.name.split('/')[1]
      return permission
    },

    switchToCreate(perm) {
      perm.showEdit = false
      this.create = true
    },
  },
}
</script>
