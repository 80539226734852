
<template>
  <ac-side-sheet-form
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @submit="$event.waitUntil(submit())"
    @load="$event.waitUntil(load())"
  >
    <template v-slot:title>Crear rol</template>
    <template v-slot:content>
      <ac-fieldset>
        <ac-input name="displayName" label="Nombre" rules="required|min:3|no_end_point" v-model="input.displayName"></ac-input>
      </ac-fieldset>
      <ac-fieldset wide>
        <ac-dual-list name="permissions" label="Permisos" :items="permissions" v-model="input.permissions"></ac-dual-list>
      </ac-fieldset>
    </template>
    <template v-slot:submit>
      <ac-submit fit>Crear rol</ac-submit>
    </template>

  </ac-side-sheet-form>
</template>

<script>
import PermissionsServiceClient from '@self/protos/permissions/permissions'
import RolesServiceClient from '@self/protos/roles/roles'
import { gliderEndpoint } from '/platform/discovery'


const permissionsClient = new PermissionsServiceClient(gliderEndpoint())
const rolesClient = new RolesServiceClient(gliderEndpoint())


export default {
  name: 'SheetCreate',

  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],

  data() {
    return {
      input: {
        displayName: '',
        permissions: [],
      },
      permissions: [],
    }
  },

  methods: {
    async load() {
      let { permissions } = await permissionsClient.List()
      this.permissions = permissions.map(perm => {
        let code = perm.name.split('/')[1]
        return {
          id: perm.name,
          label: perm.displayName,
          sublabel: code,
          search: [perm.displayName, code],
        }
      })
    },

    async submit() {
      let reply = await rolesClient.Create(this.input)

      this.router.navigate(`/${reply.name}`)
    },
  },
}
</script>
