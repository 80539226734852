
import { bearerToken } from '@altipla/auth0-admin-login'


function discovery(local, remote) {
  return {
    authorization: () => `Bearer ${bearerToken()}`,
    server: process.env.NODE_ENV === 'development' ? local : remote,
  }
}

export function gliderEndpoint() {
  return discovery('https://glider.dev.localhost', 'https://glider.api.ficheroslavoz.com')
}
