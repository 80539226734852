
<template>
  <div class="rounded-md bg-blue-50 p-8 border border-blue-200">
    <div class="flex items-center">
      <div class="flex-shrink-0">
        <ac-icon light size="4x" class="text-blue-400">file-exclamation</ac-icon>
      </div>
      <div class="text-blue-700 ml-8">
        <p class="text-sm leading-5 text-xl mb-4 font-medium">Página no encontrada</p>
        <p class="text-xs font-mono">Dirección que has intentado acceder: {{route.path}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
