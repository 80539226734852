
<template>
  <ac-side-sheet-form
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @submit="$event.waitUntil(submit())"
    @load="$event.waitUntil(load())"
  >
    <template v-slot:title>Editar usuario</template>
    <template v-slot:content>
      <ac-fieldset>
        <ac-input name="givenName" label="Nombre" rules="required|min:3|no_end_point" v-model="input.givenName"></ac-input>
      </ac-fieldset>
      <ac-fieldset>
        <ac-input name="lastname" label="Apellidos" rules="required|min:3|no_end_point" v-model="input.lastname"></ac-input>
      </ac-fieldset>
      <ac-fieldset wide>
        <ac-dual-list name="groups" label="Grupos" :items="groups" v-model="input.groups"></ac-dual-list>
      </ac-fieldset>
    </template>
    <template v-slot:submit>
      <ac-submit fit>Guardar usuario</ac-submit>
    </template>
  </ac-side-sheet-form>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { runAction } from '@altipla/promises'

import UsersServiceClient from '@self/protos/users/users'
import GroupsServiceClient from '@self/protos/groups/groups'
import { gliderEndpoint } from '/platform/discovery'


const usersClient = new UsersServiceClient(gliderEndpoint())
const groupsClient = new GroupsServiceClient(gliderEndpoint())


export default {
  name: 'SheetEdit',

  props: {
    modelValue: Boolean,
    edit: Object,
  },
  emits: ['update:modelValue', 'submit'],

  data() {
    return {
      input: {
        groups: [],
      },
      groups: [],
    }
  },

  methods: {
    async load() {
      let { groups } = await groupsClient.List()
      this.groups = groups.map(group => {
        return {
          id: group.name,
          label: group.displayName,
        }
      })

      let input = cloneDeep(this.edit)
      this.input = input
    },

    async submit() {
      await usersClient.Update(this.input)

      await runAction(this.$emit, 'submit')
    },
  },
}
</script>
