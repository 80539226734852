
<template>
  <ac-header>
    <template v-slot:breadcrumbs>
      <ac-breadcrumb url="/roles">Roles</ac-breadcrumb>
      <ac-breadcrumb :url="`/roles/${route.params.code}`">{{role.displayName}}</ac-breadcrumb>
    </template>
    <template v-slot:actions>
      <ac-button-primary @click="role.showEdit = true" class="mr-4">
        <ac-icon solid class="mr-2">pencil</ac-icon>
        Editar rol
      </ac-button-primary>
      <ac-button-confirm-danger @confirm="$event.waitUntil(deleteRole())">
        <ac-icon solid class="mr-2">trash</ac-icon>
        Eliminar rol
      </ac-button-confirm-danger>
    </template>
  </ac-header>

  <div class="mt-8 grid grid-cols-5 gap-4">
    <ac-card-table empty="Este rol no tiene permisos" class="col-span-3">
      <template v-slot:title>Permisos del rol</template>
        <template v-slot:header>
          <ac-cell-header>Código</ac-cell-header>
          <ac-cell-header>Nombre</ac-cell-header>
        </template>
        <template v-slot:rows>
          <ac-row v-for="permission in permissions" :key="permission.name">
            <ac-cell>
              <code class="font-mono text-gray-900">{{permission.code}}</code>
            </ac-cell>
            <ac-cell class="w-full" wrap>
              <router-link :to="`/${permission.name}`">{{permission.displayName}}</router-link>
            </ac-cell>
          </ac-row>
        </template>
    </ac-card-table>
    <ac-card-table empty="No hay grupos con el rol" class="col-span-2">
      <template v-slot:title>Grupos con el rol</template>
        <template v-slot:header>
          <ac-cell-header>Nombre</ac-cell-header>
        </template>
        <template v-slot:rows>
          <ac-row v-for="group in groups" :key="group.name">
            <ac-cell wrap>
              <router-link :to="`/${group.name}`">{{group.displayName}}</router-link>
            </ac-cell>
          </ac-row>
        </template>
    </ac-card-table>
  </div>
  <SheetEdit v-model="role.showEdit" :edit="role.edit" @submit="$event.waitUntil(loadRole())"></SheetEdit>
</template>

<script>
import { resolveAll } from '@altipla/promises'
import { cloneDeep } from 'lodash-es'

import PermissionsServiceClient from '@self/protos/permissions/permissions'
import RolesServiceClient from '@self/protos/roles/roles'
import GroupsServiceClient from '@self/protos/groups/groups'
import { gliderEndpoint } from '/platform/discovery'

import SheetEdit from './SheetEdit.vue'


const permissionsClient = new PermissionsServiceClient(gliderEndpoint())
const rolesClient = new RolesServiceClient(gliderEndpoint())
const groupsClient = new GroupsServiceClient(gliderEndpoint())


export default {
  components: {
    SheetEdit,
  },

  data() {
    return {
      role: {},
      permissions: [],
      groups: [],
      edit: false,
    }
  },

  async navigate() {
    let { role, permissions, groups } = await resolveAll({
      role: rolesClient.Get({ name: `roles/${this.route.params.code}` }),
      permissions: permissionsClient.List().then(reply => reply.permissions),
      groups: groupsClient.List().then(reply => reply.groups),
    })
    this.role = this.parseRole(role)
    this.permissions = permissions
      .filter(permission => role.permissions.includes(permission.name))
      .map(permission => { return { ...permission, code: permission.name.split('/')[1] } })
    this.groups = groups.filter(group => group.roles.includes(role.name))
  },

  methods: {
    async deleteRole() {
      await rolesClient.Delete({ name: this.role.name })

      this.router.navigate(`/roles`)
    },

    async loadRole() {
      let role = await rolesClient.Get({ name: `roles/${this.route.params.code}` })
      this.role = this.parseRole(role)
    },

    parseRole(role) {
      role.edit = cloneDeep(role)
      role.code = role.name.split('/')[1]
      return role
    },
  },
}
</script>

