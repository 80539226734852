
<template>
  <ac-header>
    <template v-slot:breadcrumbs>
      <ac-breadcrumb url="/groups">Grupos</ac-breadcrumb>
      <ac-breadcrumb :url="`/${group.name}`">{{group.displayName}}</ac-breadcrumb>
    </template>
    <template v-slot:actions>
      <ac-button-primary class="mr-3" @click="group.showEdit = true">
        <ac-icon solid class="mr-2">pencil</ac-icon>
        Editar grupo
      </ac-button-primary>
      <ac-button-confirm-danger 
        :disabled="users.length > 0" 
        :title="users.length > 0 ? 'No puedes borrar un grupo con usuarios asociados.' : ''" 
        @confirm="$event.waitUntil(deleteGroup())"
      >
        <ac-icon solid class="mr-2">trash</ac-icon>
        Eliminar grupo
      </ac-button-confirm-danger>
    </template>
  </ac-header>

  <div class="mt-8 grid grid-cols-2 gap-4">
    <ac-card-table empty="Este grupo no tiene roles">
      <template v-slot:title>Roles</template>
      <template v-slot:header>
        <ac-cell-header>Nombre</ac-cell-header>
      </template>
      <template v-slot:rows>
        <ac-row v-for="role in roles" :key="role.name">
          <ac-cell class="w-full">
            <router-link :to="`/${role.name}`">{{role.displayName}}</router-link>
          </ac-cell>
        </ac-row>
      </template>
    </ac-card-table>
    <ac-card-table empty="Este grupo no tiene usuarios">
      <template v-slot:title>Usuarios en el grupo</template>
      <template v-slot:header>
        <ac-cell-header>Nombre</ac-cell-header>
      </template>
      <template v-slot:rows>
        <ac-row v-for="user in users" :key="user.name">
          <ac-cell class="w-full">
            <router-link :to="`/${user.name}`">{{user.givenName}} {{user.lastname}}</router-link>
          </ac-cell>
        </ac-row>
      </template>
    </ac-card-table>
  </div>
  <SheetEdit v-model="group.showEdit" :edit="group.edit" @submit="$event.waitUntil(loadGroup())"></SheetEdit>
</template>

<script>
import { resolveAll } from '@altipla/promises'
import { cloneDeep } from 'lodash-es'

import UsersServiceClient from '@self/protos/users/users'
import RolesServiceClient from '@self/protos/roles/roles'
import GroupsServiceClient from '@self/protos/groups/groups'
import { gliderEndpoint } from '/platform/discovery'

import SheetEdit from './SheetEdit.vue'


const usersClient = new UsersServiceClient(gliderEndpoint())
const rolesClient = new RolesServiceClient(gliderEndpoint())
const groupsClient = new GroupsServiceClient(gliderEndpoint())


export default {
  components: {
    SheetEdit,
  },

  data() {
    return {
      group: {},
      roles: [],
      users: [],
    }
  },

  async navigate() {
    await this.loadGroup()
  },

  methods: {
    async deleteGroup() {
      await groupsClient.Delete({ name: this.group.name })
      this.router.navigate(`/groups`)
    },

    async loadGroup() {
      let { group, roles, users } = await resolveAll({
        group: groupsClient.Get({ name: `groups/${this.route.params.code}` }),
        roles: rolesClient.List().then(reply => reply.roles),
        users: usersClient.List().then(reply => reply.users),
      })
      
      this.group = group
      group.edit = cloneDeep(group)
      group.code = group.name.split('/')[1]

      this.roles = roles.filter(role => group.roles.includes(role.name))
      this.users = users.filter(user => user.groups.includes(group.name))
    },
  },
}
</script>
