
<template>
  <div class="mx-auto max-w-7xl pb-32">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'ac-app-layout',
}
</script>
