
<template>
  <ac-acl-tabs selected="permissions">
    <ac-button-primary @click="create = true">
      <ac-icon solid class="mr-2">plus</ac-icon>
      Crear permiso
    </ac-button-primary>
  </ac-acl-tabs>

  <ac-card-table empty="No hay permisos que coincidan con el filtro">
    <template #title>Permisos</template>
    <template #description>Se crean por programación para controlar el acceso a recursos.</template>
    <template #actions>
      <ac-search-input placeholder="Filtrar permisos..." v-model="table.filter" v-if="table"></ac-search-input>
    </template>

    <template #header>
      <ac-cell-header class="w-full">Nombre</ac-cell-header>
      <ac-cell-header>Código</ac-cell-header>
      <ac-cell-header></ac-cell-header>
    </template>

    <template #rows v-if="table">
      <ac-row v-for="perm in table.rows" :key="perm.name">
        <ac-cell class="w-full">
          <router-link :to="`/${perm.name}`" class="text-gray-900">
            {{perm.displayName}}
          </router-link>
        </ac-cell>
        <ac-cell bold>
          <code class="font-mono text-gray-400">{{perm.code}}</code>
        </ac-cell>
        <ac-cell>
          <a :href="`/${perm.name}`" @click.prevent="perm.showEdit = true">Editar</a>
        </ac-cell>
      </ac-row>
    </template>

    <template #footer>
      <ac-pagination :table="table"></ac-pagination>
    </template>
  </ac-card-table>

  <SheetCreate v-model="create" @submit="$event.waitUntil(loadPerms())"></SheetCreate>
  <template v-if="table">
    <template v-for="perm in table.rows" :key="perm.name">
      <SheetEdit v-model="perm.showEdit" :edit="perm.edit" @submit="$event.waitUntil(loadPerms())" @create="switchToCreate(perm)"></SheetEdit>
    </template>
  </template>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { useTable } from '@altipla-consulting/ui-v1'

import PermissionsServiceClient from '@self/protos/permissions/permissions'
import { gliderEndpoint } from '/platform/discovery'

import SheetCreate from './SheetCreate.vue'
import SheetEdit from './SheetEdit.vue'


const client = new PermissionsServiceClient(gliderEndpoint())


export default {
  components: {
    SheetCreate,
    SheetEdit,
  },

  data() {
    return {
      table: null,
      create: false,
    }
  },

  async navigate() {
    await this.loadPerms()
  },

  methods: {
    async loadPerms() {
      let { permissions } = await client.List()

      this.table = useTable({
        rows: permissions.map(perm => {
          perm.edit = cloneDeep(perm)
          perm.code = perm.name.split('/')[1]

          return perm
        }),
        search: perm => [perm.displayName, perm.code],
      })
    },
    
    switchToCreate(perm) {
      perm.showEdit = false
      this.create = true
    },
  },
}
</script>
