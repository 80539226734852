
import { createApp } from 'vue'
import Hermes from '@altipla/hermes'
import ProductionStack from '@altipla/production-stack-vue'
import '@altipla-consulting/ui-v1/dist/style.css'
import {
  UIV1,
  Header,
  BadgeRed,
  Breadcrumb,
  ButtonPrimary,
  ButtonSuccess,
  ButtonLinkPrimary,
  ButtonDanger,
  ButtonHoverDanger,
  ButtonConfirmDanger,
  ButtonAsyncSuccess,
  ButtonAsyncHoverDanger,
  ExternalLink,
  ButtonWhite,
  Table,
  Row,
  CellHeader,
  Cell,
  Card,
  CardTable,
  Show,
  ShowPane,
  CopyText,
  SearchInput,
  Form,
  Fieldset,
  Submit,
  Input,
  DualList,
  Throbber,
  SideSheet,
  SideSheetForm,
  AlertSuccess,
  AlertError,
  Pagination,
  TabsButton,
  TabButton,
  Tabs,
  Tab,
} from '@altipla-consulting/ui-v1'
import { Authenticator } from '@altipla/auth0-admin-login'

import './styles.css'
import './icons'
import routes from './routes'

import AppLayout from './components/AppLayout.vue'
import AclTabs from './components/AclTabs.vue'


let app = createApp(AppLayout)

app.use(ProductionStack, {
  sentryDSN: 'https://ad76f12aefc44c86bdacb03e9437b1e7@o50500.ingest.sentry.io/5923213',
  version: import.meta.env.VITE_VERSION,
})
let auth = new Authenticator({
  domain: 'lavoz.eu.auth0.com',
  clientId: import.meta.env.DEV ? 'E1ygFQnOktNc5DqDzgyUIQzaAllIhORk' : 'Pl3EJAR6xvtiA6WBjDfxlgAbvqFC04NM',
  audience: 'https://glider.lavozdealmeria.com/',
})
app.use(Hermes, auth.configureHermes({ routes }))
app.use(UIV1, [
  Header,
  BadgeRed,
  Breadcrumb,
  ButtonPrimary,
  ButtonSuccess,
  ButtonLinkPrimary,
  ButtonDanger,
  ButtonHoverDanger,
  ButtonConfirmDanger,
  ButtonAsyncSuccess,
  ButtonAsyncHoverDanger,
  ExternalLink,
  ButtonWhite,
  Table,
  Row,
  CellHeader,
  Cell,
  Card,
  CardTable,
  Show,
  ShowPane,
  CopyText,
  SearchInput,
  Form,
  Fieldset,
  Submit,
  Input,
  DualList,
  Throbber,
  SideSheet,
  SideSheetForm,
  AlertSuccess,
  AlertError,
  Pagination,
  TabsButton,
  TabButton,
  Tabs,
  Tab,
  AclTabs,
])

app.mount('#app')
