
import MiscError from './views/misc/MiscError.vue'
import MiscNotFound from './views/misc/MiscNotFound.vue'
import PermissionsList from './views/permissions/PermissionsList.vue'
import PermissionsShow from './views/permissions/PermissionsShow.vue'
import RolesList from './views/roles/RolesList.vue'
import RolesShow from './views/roles/RolesShow.vue'
import GroupsList from './views/groups/GroupsList.vue'
import GroupsShow from './views/groups/GroupsShow.vue'
import UsersList from './views/users/UsersList.vue'
import UsersShow from './views/users/UsersShow.vue'


export default [
  { path: '/', redirect: '/users' },
  { path: '/acl', redirect: '/groups' },

  { path: '/permissions', component: PermissionsList },
  { path: '/permissions/:code', component: PermissionsShow },

  { path: '/roles', component: RolesList },
  { path: '/roles/:code', component: RolesShow },

  { path: '/groups', component: GroupsList },
  { path: '/groups/:code', component: GroupsShow },

  { path: '/users', component: UsersList },
  { path: '/users/:code', component: UsersShow },

  { path: '[error]', component: MiscError },
  { path: '*', component: MiscNotFound },
]
