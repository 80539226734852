
<template>
  <ac-side-sheet-form
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @submit="$event.waitUntil(submit())"
    @load="$event.waitUntil(load())"
  >
    <template v-slot:title>Crear permiso</template>
    <template v-slot:content>
      <ac-fieldset>
        <ac-input class="w-96" name="code" label="Código" rules="required" v-model="input.code"></ac-input>
      </ac-fieldset>
      <ac-fieldset>
        <ac-input name="displayName" label="Nombre" rules="required|min:3|no_end_point" v-model="input.displayName"></ac-input>
      </ac-fieldset>
      <ac-fieldset wide>
        <ac-dual-list name="roles" label="Roles" :items="roles" v-model="input.roles"></ac-dual-list>
      </ac-fieldset>
    </template>
    <template v-slot:submit>
      <div class="flex space-x-reverse space-x-4 flex-row-reverse">
        <ac-submit fit>
          Crear permiso
        </ac-submit>
        <ac-submit fit @click="createClose = true" button="ac-button-white">
          <ac-icon solid class="mr-2">repeat</ac-icon>
          Crear y volver al listado
        </ac-submit>
      </div>
    </template>
  </ac-side-sheet-form>
</template>

<script>
import { runAction } from '@altipla/promises'

import PermissionsServiceClient from '@self/protos/permissions/permissions'
import RolesServiceClient from '@self/protos/roles/roles'
import { gliderEndpoint } from '/platform/discovery'


const permissionsClient = new PermissionsServiceClient(gliderEndpoint())
const rolesClient = new RolesServiceClient(gliderEndpoint())


export default {
  name: 'SheetCreate',

  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue', 'submit'],

  data() {
    return {
      input: {},
      roles: [],
      createClose: false,
    }
  },

  methods: {
    async load()  {
      this.input = {
        code: '',
        displayName: '',
        roles: [],
      }
      this.createClose = false

      let { roles } = await rolesClient.List()
      this.roles = roles.map(role => {
        return {
          id: role.name,
          label: role.displayName,
        }
      })
    },

    async submit() {
      let permission = {
        name: `permissions/${this.input.code}`,
        displayName: this.input.displayName,
      }
      let reply = await permissionsClient.Create(permission)

      let { roles } = await rolesClient.List()
      roles = roles.filter(role => this.input.roles.includes(role.name))

      for (let role of roles) {
        role.permissions.push(permission.name)
        await rolesClient.Update(role)
      }

      if (this.createClose) {
        await runAction(this.$emit, 'submit')
      } else {
        this.router.navigate(`/${reply.name}`)
      }
    },
  },
}
</script>
