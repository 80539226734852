
<template>
  <ac-header>
    <template #breadcrumbs>
      <ac-breadcrumb url="/acl">Gestión de permisos</ac-breadcrumb>
    </template>
    <template #actions>
      <slot></slot>
    </template>
  </ac-header>

  <ac-tabs class="mb-8">
    <ac-tab name="groups" to="/groups" :selected="selected === 'groups'">Grupos</ac-tab>
    <ac-tab name="roles" to="/roles" :selected="selected === 'roles'">Roles</ac-tab>
    <ac-tab name="permissions" to="/permissions" :selected="selected === 'permissions'">Permisos</ac-tab>
  </ac-tabs>
</template>

<script>
export default {
  name: 'ac-acl-tabs',

  props: {
    selected: {
      type: String,
      required: true,
    },
  },
}
</script>
