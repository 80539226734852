
<template>
  <ac-side-sheet-form
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @submit="$event.waitUntil(submit())"
    @load="$event.waitUntil(load())"
  >
    <template v-slot:title>Editar grupo</template>
    <template v-slot:content>
      <ac-fieldset>
        <ac-input name="displayName" label="Nombre" rules="required|min:3|no_end_point" v-model="input.displayName"></ac-input>
      </ac-fieldset>
      <ac-fieldset wide>
        <ac-dual-list name="roles" label="Roles" :items="roles" v-model="input.roles"></ac-dual-list>
      </ac-fieldset>
    </template>
    <template v-slot:submit>
      <ac-submit fit>Guardar grupo</ac-submit>
    </template>
  </ac-side-sheet-form>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { runAction } from '@altipla/promises'

import GroupsServiceClient from '@self/protos/groups/groups'
import RolesServiceClient from '@self/protos/roles/roles'
import { gliderEndpoint } from '/platform/discovery'


const groupsClient = new GroupsServiceClient(gliderEndpoint())
const rolesClient = new RolesServiceClient(gliderEndpoint())


export default {
  name: 'SheetEdit',

  props: {
    modelValue: Boolean,
    edit: Object,
  },
  emits: ['update:modelValue', 'submit'],

  data() {
    return {
      input: {
        displayName: '',
        roles: [],
      },
      roles: [],
    }
  },

  methods: {
    async load() {
      this.input = cloneDeep(this.edit)

      let { roles } = await rolesClient.List()
      this.roles = roles.map(role => {
        return {
          id: role.name,
          label: role.displayName,
        }
      })
    },

    async submit() {
      await groupsClient.Update(this.input)

      await runAction(this.$emit, 'submit')
    },
  },
}
</script>
