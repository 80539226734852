
<template>
  <ac-side-sheet-form
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @submit="$event.waitUntil(submit())"
    @load="$event.waitUntil(load())"
  >
    <template v-slot:title>Crear usuario</template>
    <template v-slot:content>
      <ac-fieldset>
        <ac-input name="email" label="Email" rules="required|email" v-model="input.email"></ac-input>
        <ac-alert-error v-if="alreadyExists" class="mt-4">
          <template v-slot:icon><ac-icon solid>times-circle</ac-icon></template>
          <template v-slot:title>
            El email que has introducido pertenece a
            <router-link :to="`/${alreadyExists.name}`">
              {{alreadyExists.givenName}} {{alreadyExists.lastname}}
            </router-link>
          </template>
        </ac-alert-error>
      </ac-fieldset>
      <ac-fieldset>
        <ac-input name="givenName" label="Nombre" rules="required|min:3|no_end_point" v-model="input.givenName"></ac-input>
      </ac-fieldset>
      <ac-fieldset>
        <ac-input name="lastname" label="Apellidos" rules="required|min:3|no_end_point" v-model="input.lastname"></ac-input>
      </ac-fieldset>
      <ac-fieldset wide>
        <ac-dual-list name="groups" label="Grupos" :items="groups" v-model="input.groups"></ac-dual-list>
      </ac-fieldset>
    </template>
    <template v-slot:submit>
      <ac-submit :disabled="!!alreadyExists" fit>Crear usuario</ac-submit>
    </template>
  </ac-side-sheet-form>
</template>

<script>
import { resolveAll } from '@altipla/promises'

import UsersServiceClient from '@self/protos/users/users'
import GroupsServiceClient from '@self/protos/groups/groups'
import { gliderEndpoint } from '/platform/discovery'

import { showPassword } from './UsersShow.vue'


const usersClient = new UsersServiceClient(gliderEndpoint())
const groupsClient = new GroupsServiceClient(gliderEndpoint())


export default {
  name: 'SheetCreate',

  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],

  data() {
    return {
      input: {
        email: '',
        givenName: '',
        lastname: '',
        groups: [],
      },
      groups: [],
      users: [],
    }
  },

  computed: {
    alreadyExists() {
      return this.users.find(user => user.email === this.input.email)
    },
  },

  methods: {
    async load() {
      let { users, groups } = await resolveAll({
        users: usersClient.List().then(reply => reply.users),
        groups: groupsClient.List().then(reply => reply.groups),
      })

      this.groups = groups.map(group => {
        return {
          id: group.name,
          label: group.displayName,
        }
      })
      this.users = users
    },

    async submit() {
      if (this.alreadyExists) {
        return
      }

      let reply = await usersClient.Create(this.input)
      showPassword(reply.password)
      this.router.navigate(`/${reply.user.name}`)
    },
  },
}
</script>
